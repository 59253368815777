<template>
  <div>
    <v-container :class="isMobile ? 'pa-0' : 'px-8'">
      <v-row :class="isMobile ? 'pr-5' : 'px-12'"
        ><v-col cols="12" sm="12" md="12" lg="12">
          <v-card outlined color="transparent">
            <v-card-title
              ><span style="color:#9575cd;">News</span></v-card-title
            >
            <v-divider></v-divider>
            <div v-if="posts.length > 0">
              <v-list>
                <v-list-group
                  v-for="post in posts"
                  :key="post._id"
                  v-model="post.active"
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item>
                      <v-list-item-content>
                        <!-- <v-list-item-text v-if="isMobile"
                          >{{
                            post.title.length > 50
                              ? post.title.slice(0, 50) + '...'
                              : post.title.slice(0, 50)
                          }}
                        </v-list-item-text>
                        <v-list-item-title v-if="!isMobile">
                          {{
                            !isMobile && post.title.length > 65
                              ? post.title.slice(0, 65) + '...'
                              : post.title.slice(0, 65)
                          }}</v-list-item-title
                        > -->
                        <v-list-item-text
                          style="line-height: 1.5;"
                          class="text--primary text-caption text-sm-subtitle-1"
                          >{{ post.title }}</v-list-item-text
                        >
                        <v-list-item-subtitle
                          :style="isMobile ? 'font-size:0.75rem;' : ''"
                          v-text="`${post.updatedAt}`"
                        />
                        <v-list-item-subtitle
                          :style="isMobile ? 'font-size:0.75rem;' : ''"
                          v-text="`${post.fromNow}`"
                        />
                      </v-list-item-content>
                    </v-list-item>
                    <!-- <v-list-item-content>
                      <v-list-item-title
                        v-text="post.title"
                      ></v-list-item-title>
                    </v-list-item-content> -->
                  </template>

                  <v-list-item :class="isMobile ? 'px-0 pl-8' : ''">
                    <v-list-item-content>
                      <v-list-item-text
                        style="line-height: 1.25;"
                        class="text--primary text-caption text-sm-body-2"
                        v-html="post.content.slice(0, 250) + '</br>' + '...'"
                      ></v-list-item-text>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        :x-small="isMobile ? true : false"
                        depressed
                        color="#9575cd"
                        dark
                        @click="clickPost(post._id)"
                      >
                        Read More
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </div>
            <div class="ma-5" v-else>게시물이 존재하지 않습니다.</div>
          </v-card></v-col
        >
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'HomeNotice',
  data() {
    return {
      posts: '',
      mobileTitle: '',
    };
  },
  props: ['isMobile'],
  methods: {
    getPosts() {
      axios.get(`${this.$ROOT_API}/posts`).then((res) => {
        this.posts = res.data.posts.slice(0, 5);
        this.posts.map((post) => {
          post.fromNow = moment(post.createdAt).fromNow();
          post.updatedAt = moment(post.updatedAt).format('yyyy-MM-DD ddd');
        });
      });
    },
    clickPost(id) {
      axios.post(`${this.$ROOT_API}/posts/${id}`).then(
        this.$router.push({
          name: 'Video Post Detail',
          params: { id: id },
        })
      );
    },
    converHTMLtoText(e) {
      return e.replace(/<[^>]+>/g, '');
    },
  },
  created() {
    this.getPosts();
  },
};
</script>

<style lang="scss" scoped>
.circle-image {
  border-radius: 5%;
}
ul {
  list-style: none;
}
ul li::before {
  content: '\2022';
  color: #4caf50;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
* {
  line-height: 2;
}
</style>
