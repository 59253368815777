<template>
  <v-carousel cycle height="500" hide-delimiter-background show-arrows-on-hover>
    <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
      <!-- <v-sheet height="100%">
        <v-row class="fill-height" align="center" justify="center">
          <div class="display-3">{{ item.content }}</div>
        </v-row>
      </v-sheet> -->
      <v-row class="fill-height" align="start" justify="end">
        <div class="display-2" style="position:relative; top:50px; right:50px;">
          <p style="font-weight: bold;" class="slider_content">
            {{ item.content }}
          </p>
        </div>
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          content: 'Love is love is love',
          src:
            'https://drive.google.com/uc?export=view&id=1o912whheNfQSx6kA76v4TJO2eeZYJn8u',
        },
        {
          content: '긍지와 자유로 세상을 칠해요',
          src:
            'https://drive.google.com/uc?export=view&id=1dmjGLRiLN5HIsfOvwh48LhFROR_hv5iW',
        },
        {
          content: '하나님의 형상으로 퀴어를 만드셨으니…',
          src:
            'https://drive.google.com/uc?export=view&id=1hyyO_RCQs9AihDNFxeV7Ckyq8w50lBTv',
        },
        // {
        //   content: '하나님의 사랑 평등 환대',
        //   src:
        //     'https://drive.google.com/uc?export=view&id=1yEaSHsKKtN_hN4zyVh89S-HtEc2EqZJ0',
        // },
        {
          content: '하나님의 사랑 평등 환대',
          src:
            'https://drive.google.com/uc?export=view&id=1ZGZXzzNd3D5Z8o1dtAivVpzwu9BEf0a2',
        },
        // {
        //   content: '하나님의 사랑 평등 환대',
        //   src:
        //     'https://drive.google.com/uc?export=view&id=1Gls-xAZ7ErXa32CNV8JL7Wa9A3atzrTa',
        // },
      ],
    };
  },
};
</script>
<style>
.slider_content {
  font-family: 'Gugi', cursive;
}
</style>
