<template>
  <v-container class="">
    <v-row :class="isMobile ? '' : 'px-16'">
      <v-col
        v-if="isMobile"
        cols="12"
        sm="12"
        md="12"
        lg="6"
        class="d-flex justify-center align-center"
        ><v-img
          class="circle-image"
          src="../assets/images/Home/main1.jpg"
          max-width="700"
          aspect-ratio="1.5"
      /></v-col>
      <v-col
        cols="12"
        lg="6"
        md="12"
        sm="12"
        class="d-flex align-center justify-center"
        ><v-card outlined color="transparent" max-width="700">
          <v-card-title><span style="color:#9575cd;">소개</span></v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="text--primary text-caption text-sm-body-1">
              <ul>
                <li>
                  <b>Korean Rainbow United</b> (한국명: ”캐나다연합교회 무지개
                  연대”)는 캐나다연합교회에서 활동하고 있는 한인 퀴어와 엘라이,
                  목회자와 평신도로 구성된 느슨한 연대 네트워크로서
                  호모포비아적(이성애자 중심의 혐오적) 이데올로기를 바탕으로 한
                  성서 읽기, 해석, 가르침으로 인해 발생하는 낙인 및 차별에
                  저항하는 한국 내/외의 운동에 동참하며 목소리를 낸다.
                </li>
                <br />
                <li>
                  <b>Korean Rainbow United</b> is a network of
                  United Church of Canada for Korean queer people and allies,
                  lay and clergy and their co-creating relationship. Our mission
                  is to unite our voice with the affirming Christian alliances
                  in Korea and beyond, and join in the
                  “RainbowYesu(RainbowJesus)” activism currently struggling in
                  Korea to resist the discrimination, hate and stigma caused by
                  homophobic/heterosexist scripture reading, interpretation and
                  teaching.
                </li>
              </ul>
            </div></v-card-text
          >
        </v-card></v-col
      >
      <v-col
        v-if="!isMobile"
        cols="12"
        sm="12"
        md="12"
        lg="6"
        class="d-flex align-center justify-center"
        ><v-img
          class="circle-image"
          src="../assets/images/Home/main1.jpg"
          max-width="700"
          aspect-ratio="1.5"
      /></v-col>
    </v-row>
    <v-row :class="isMobile ? '' : 'mb-6 pl-16 pr-16'">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="6"
        class="d-flex justify-center align-center"
      >
        <v-img
          class="circle-image"
          aspect-ratio="1.5"
          src="../assets/images/Home/우리의 중요 사역.jpg"
          max-width="700"
        />
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="12"
        sm="12"
        class="d-flex align-center justify-center"
        ><v-card outlined color="transparent" max-width="700">
          <v-card-title
            ><span style="color:#9575cd;">프로젝트</span></v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <div class="text--primary text-caption text-sm-body-1">
              <p>우리의 중요 사역</p>
              <ul>
                <li>
                  UCC의 성소수자 포용 관련 자료, 퀴어 신학 자료를 한국어로 번역
                </li>
                <li>
                  성소수자를 포용하고 사랑하는 기독교 정신을 담은 기도, 찬양,
                  정보 동영상 제작
                </li>
                <li>
                  성소수자 포용을 위한 다양한 토론의 장과 퀴어 신학,
                  성소수자인권 관련 포럼 및 워크샵을 개최
                </li>
                <li>
                  “무지개예수” (한국의 성소수자 지원 기독교 연대체)와의 긴밀한
                  협력과 활동 참여
                </li>
                <li>
                  북미 한국인 성소수자 및 앨라이들의 고민과 아픔을 함께 나눌 수
                  있는 대화와 상담 창구 마련
                </li>
              </ul>
              <br /></div
          ></v-card-text> </v-card
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default { props: ['isMobile'] };
</script>

<style lang="scss" scoped>
.circle-image {
  border-radius: 5%;
}
ul {
  list-style: none;
}
ul li::before {
  content: '\2022';
  color: #4caf50;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
* {
  line-height: 2;
}
</style>
