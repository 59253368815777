<template>
  <div>
    <Slider v-if="!isMobile" />
    <HomeNotice v-bind:isMobile="isMobile" />
    <Introduction v-bind:isMobile="isMobile" />
  </div>
</template>

<script>
import Slider from '../components/Slider';
import Introduction from '../components/Introduction';
import HomeNotice from '../components/HomeNotice';

export default {
  name: 'Home',
  components: { Slider, Introduction, HomeNotice },
  data() {
    return {
      posts: [],
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  computed: {
    isMobile() {
      return this.windowWidth < 960;
    },
  },
};
</script>

<style lang="scss" scoped></style>
